$( function() {

	$('ul.rate').on('click touch', 'a', function(e) {
		e.preventDefault();
		var pid = $(this).closest('ul').data('pid');
		var $that = $(this);
		if(!hasRated(pid)) {
			var url = $(this).attr('href');
			$.post(url, function(res) {
				console.log(res);
				if(!res.error) {
					addRating(pid);
					$that.closest('li').addClass('rated');
					$that.closest('ul.rate').addClass('inactive');
					alert('Danke für deine Bewertung.');
					$rvlist = $('ul.rating-view');
					$rvlist
						.removeClass('score-0')
						.removeClass('score-1')
						.removeClass('score-2')
						.removeClass('score-3')
						.removeClass('score-4')
						.removeClass('score-5');
					$rvlist.addClass('score-'+Math.round(res.avg));
					$rvlist.closest('.rating').find('.rating-cnt').text(res.count);
				}
				else {
					alert(res.error_msg[0]);
				}
			}, 'json');
		}
		else {
			alert('Du hast diesen Beitrag bereits bewertet.');
		}
	});

	function getPreviousRatings() {
		var ratings_string = localStorage.getItem('_r');
		res = [];
		if(ratings_string !== null) {
			try {
				res = JSON.parse(ratings_string);
			}
			catch(e) {
				res = [];
			}

		}
		return Array.isArray(res) ? res : [];
	}

	function hasRated(post_id) {
		var ratings = getPreviousRatings();
		console.log(ratings, post_id);
		return (ratings.indexOf(post_id) !== -1);
	}

	function addRating(post_id) {
		var ratings = getPreviousRatings();
		ratings.push(post_id);
		saveRatings(ratings);
	}

	function saveRatings(ratings_array) {
		console.log('saving', ratings_array);
		localStorage.setItem('_r', JSON.stringify(ratings_array));
	}

	$('a[href="#rate-form-anchor"]').on('click', function(evt) {
		evt.preventDefault();
		$target = $('a[name=rate-form-anchor]');
		var y = $target.get(0).offsetTop;
		if( !$('#shadowheader').is(":hidden") ) {
			y = y - $('#shadowheader').height();
		}

		$([document.documentElement, document.body]).animate({
			scrollTop: y
		}, 1000);
	});
});