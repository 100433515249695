var _topMargin = 0;
$( function() {

	if($('.share.desktop').length) {
		var $shareDesktopElement = $('.share.desktop');

		_topMargin = 0;
		var _bottomMargin = 99999;
		var _currentOffset = 0;
		getMargins();
		//repositionSharing();

		function getMargins() {
			_topMargin = ($('.hero').offset().top + $('.hero').outerHeight());
			_bottomMargin = ($('.post-content').offset().top + $('.post-content').outerHeight());
		}
		
		function repositionSharing() {
			var x = Math.max($(document).scrollTop(), _topMargin);
			_currentOffset = Math.min(x, _bottomMargin);
		}

		function toggleStickyness() {
			if($(document).scrollTop() > _topMargin && $(document).scrollTop() < _bottomMargin) {
				$shareDesktopElement.toggleClass('sticky', true).css('top', 0);
			}
			else {
				$shareDesktopElement.toggleClass('sticky', false).css('top', _currentOffset);
			}
		}

		$(window).on('resize', function() {
			getMargins();
			repositionSharing();
			toggleStickyness();
		});

		$(window).on('scroll', function() {
			repositionSharing();
			toggleStickyness();
		});
	}

	if($('.share.mobile').length) {
		var $shareMobileElement = $('.share.mobile');

		var _bottomMargin = 99999;
		var viewport_height = 0;
		getMargins();
		repositionSharing();

		function getMargins() {
			viewport_height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
			_bottomMargin = ($('.post-content').offset().top + $('.post-content').outerHeight());
		}
		
		function repositionSharing() {
			//console.log(_bottomMargin, $(window).scrollTop());
			if( (_bottomMargin - viewport_height) > $(window).scrollTop()) {
				$shareMobileElement.addClass('sticky');
			}
			else {
				$shareMobileElement.removeClass('sticky');
			}
		}

		$(window).on('resize', function() {
			getMargins();
			repositionSharing();
		});

		$(window).on('scroll', function() {
			repositionSharing();
		});
	}
});