$( function() {
	var paged = 2;
	var loading = false;

	$('#loadmore').on('click', function(evt) {
		evt.preventDefault();
		var $_btn = $(this);
		if( $_btn.hasClass('inactive') ) {
			return;
		}
		if(!loading) {
			$list = $('.list-posts');

			var _data = {};
			_data.author = $list.data('author');
			_data.tpl = $list.data('tpl');
			_data.tag = $list.data('tag');
			_data.category = $list.data('category');
			_data.exclude = $list.data('exclude');
			loading = true;
			$.ajax('/getposts/page/' + paged + '/', {
				data: _data,
				type: 'get',
				success: function(res) {
					console.log(res);
					loading = false;
					if(res.length) {
						paged = paged + 1;
					}
					else {
						$_btn.addClass('inactive');
					}
					$list.append(res);
				}
			});
		}
	});
});