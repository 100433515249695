$( function() {
	$('a#togglenav').on('click', function(e) {
		e.preventDefault();
		$(this).toggleClass('fa-times');
		$('header').toggleClass('navigation-visible');
		$('header').removeClass('search-visible');
		$('a#togglesearch').removeClass('fa-times');
	});
	$('a#togglesearch').on('click', function(e) {
		e.preventDefault();
		$(this).toggleClass('fa-times');
		$('header').toggleClass('search-visible');
		$('header').removeClass('navigation-visible');
		$('a#togglenav').removeClass('fa-times');
	});

	function adjustShadowHeader() {
		$('#shadowheader').height( $('header').outerHeight() );
	}

	adjustShadowHeader();

	$(window).on('resize', adjustShadowHeader);


});