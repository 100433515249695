$( function() {
	var _exclude_cookies = ['PHPSESSID', 'Anticookie', 'phunk_cookies_notice_showed'];

	function removeCookies() {
		$.each($.cookie(), function(k, v) {
			if(_exclude_cookies.indexOf(k) == -1) {
				document.cookie = k+'=; domain=.blog-ergo.de; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
			}
		});
	}

	$('a.no-cookies').on('click', function(evt) {
		evt.preventDefault();
		cookieOptOut();
		removeCookies();
		$(this).closest('p').append( $('<span class="no-cookie-feedback">Sie haben erfolgreich widersprochen</span>') );
	});

	function cookieOptOut() {
		var d = new Date();
		d.setTime(d.getTime() + (365*2*24*60*60*1000));
		document.cookie = 'Anticookie=1; domain=.blog-ergo.de; expires='+d.toUTCString()+'; path=/';
	}

	$('.phunk-cookie-notice').find('a.notice-close').on('click', function(evt) {
		evt.preventDefault();
		var d = new Date();
		d.setTime(d.getTime() + (365*2*24*60*60*1000));
		document.cookie = 'phunk_cookies_notice_showed=true; domain=.blog-ergo.de; expires='+d.toUTCString()+'; path=/';
		$(this).closest('.phunk-cookie-notice').hide(200).queue().remove();
	});
});