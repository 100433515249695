$( function() {

	function pollInit() {
		$('.poll-widget').each( function() {
			$poll = $(this);
			if( !hasVoted($poll.data('poll')) ) {
				$poll.addClass('active');
				bindAction($poll);
			}
			else {
				console.log('draw');
				drawResults($poll);
			}
		});
	}
	pollInit();

	function bindAction($poll) {
		var pid = $poll.data('poll');
		$poll.on('click touch', 'span.vote-action', function(e) {
			e.preventDefault();
			var url = $(this).data('href');
			if( !hasVoted(pid) ) {
				$.post(url, function(res) {
					console.log(res);
					if(!res.error) {
						addVote(pid);

						updatePoll($poll, res.results)
						drawResults($poll);
					}
					else {
						alert(res.error_msg[0]);
						drawResults($poll);
					}
				}, 'json');
			}
			else {
				alert("Du hast bereits abgestimmt.");
				drawResults($poll);
			}
		});
	}

	function updatePoll($poll, votes) {
		var i;
		var len = votes.length
		for( i = 0; i < len; i++ ) {
			$poll.find('div.votes').filter( function() {
				return $(this).data('answer') == votes[i].answer_index;
			})
			.data('cnt', votes[i].cnt)
			.data('percent', votes[i].percent)
			.closest('li')
			.find('span.percent')
			.data('percent', votes[i].percent);
		}
	}

	function drawResults($poll) {
		$poll.removeClass('active');
		$poll.find('div.votes').each( function() {
			$(this).css( 'width', $(this).data('percent') + '%');
		});
		$poll.find('span.vote-action').on('click touch', function(e) {
			e.preventDefault();
		});
		countimate($poll.find('span.percent'));

	}

	function getPreviousVotes() {
		var votes_string = localStorage.getItem('_v');
		res = [];
		if(votes_string !== null) {
			try {
				res = JSON.parse(votes_string);
			}
			catch(e) {
				res = [];
			}

		}
		return Array.isArray(res) ? res : [];
	}

	function hasVoted(poll_id) {
		var votes = getPreviousVotes();
		return (votes.indexOf(poll_id) !== -1);
	}

	function addVote(poll_id) {
		var votes = getPreviousVotes();
		votes.push(poll_id);
		saveVotes(votes);
	}

	function saveVotes(votes_array) {
		console.log('saving', votes_array);
		localStorage.setItem('_v', JSON.stringify(votes_array));
	}

	function countimate($el) {
		$el.filter( function() {
			return !$(this).hasClass('triggered');
		}).each(function () {
			$(this).addClass('triggered');

			var _counter = parseInt($(this).data('percent'));
			var isFloat = $(this).data('isfloat');
			if(isFloat) {
				var precision = parseInt( $(this).data('precision') );
				var decpoint = $(this).data('decpoint') || ',';
			}
			var prefix = $(this).data('prefix') || '';
			var suffix = $(this).data('suffix') || '';

			$(this).prop( 'Counter', 0 ).animate({
				Counter: _counter
			},
			{
				duration: 2000,
				easing: 'swing',
				step: function (now) {

					var _val = now;
					if( isFloat ) {
						_val = ""+Math.round2( _val, precision );
						_val = _val.replace('.', decpoint);
					}
					else {
						_val = Math.ceil(_val);
					}
					$(this).text( prefix + _val + suffix );
				}
			});
		});
	}
});